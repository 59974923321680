@import url('./tailwind.css');

html, body {
  color: white;
}

body {
  background-color: #000;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  height: 100%;
}

#root {
  height: 100%;
  min-height: 100vh;
}

.btn-linear {
  background: linear-gradient(95.75deg, #678AFC 2.4%, #6D57F0 37.43%, #905EFB 76.01%, #6D53EF 99.87%);
  box-shadow: 0px 4px 23px rgba(109, 87, 241, 0.5);
  border-radius: 8px;
}

.text-linear {
  background: linear-gradient(95.75deg, #678AFC 2.4%, #6D57F0 37.43%, #905EFB 76.01%, #6D53EF 99.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* border */
.border-linear {
  border-image: linear-gradient(95.75deg, #678AFC 2.4%, #6D57F0 37.43%, #905EFB 76.01%, #6D53EF 99.87%) 1;
}
.bg-linear {
  background: linear-gradient(#648FFC, #6C52EE) padding-box, linear-gradient(to right, #678AFC, #6C52EE) border-box
}

/* customize scroll bar */
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* customize slick */
.slick-list { 
  margin: 0 -7px; 
}

.slick-list .slick-slide > div { 
  padding: 0 20px; 
}

